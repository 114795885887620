export default function (id, t = 'receita', parent = null) {
  let window = typeof id !== 'undefined' ? `fin-categoria.${id}.show` : 'fin-categoria.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Categorias de Contas',
    move: false,
    backdrop: true,
    clickOutside: false,
    closeOnEsc: false,
    windowClass: 'erp-window-modern v2 no-border-radius',
    width: '700',
    height: '600',
    props: {
      id: id,
      tipo: t,
      $router: this.$router,
      parent: parent
    }
  }, () => import('../components/CategoriaWindow'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          this.load && this.load()
        },
        created: (wid, val) => {
          this.load && this.load()
        }
      })
    }) // return wid
}
