<template>
  <div class="categoria-item">
    <div class="item">
      <div>
        <div class="cat-title">{{c.descricao}}</div>
        <div class="dre m-t-xxs" v-if="c.dre">DRE: <strong>{{c.dre.descricao}}</strong></div>
      </div>
      <div>
        <u-btn v-if="!isLastLevel" @click="$emit('new', c)" icon="plus" icon-type="fa" class="btns m-r-sm" />
        <u-btn @click="$emit('edit', c)" icon="pen" icon-type="fa" class="btns m-r-sm" />
        <u-btn @click="$emit('remove', c)" icon="trash" icon-type="fa" class="btns" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "cetegoria-item",
  props: {
    c: {
      required: true
    },
    isLastLevel: {
      type: Boolean,
      default: false
    }
  }
}
</script>
