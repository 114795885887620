<script>
/* eslint-disable */
import {listCategoria as list, deleteCategoria} from '@/domain/financeiro/services/categoria'
import {date, UFakeLoader} from 'uloc-vue'
import {datePtToEn} from '@/utils/date'
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import DateInput from "@/reuse/input/Date"
import {listName, listStorage} from "@/components/financeiro/config/listCategorias"
import ListMixin from "@/reuse/list/ListMixin"
import LayoutPaper from "@/components/layout/LayoutPaper"
import windowNew from '../windows/categoria'
import CategoriaItem from './lista/categoria-item'

const filters = {
  id: null,
  active: true,
  deleted: false,
  search: null,
  status: null
}

export default {
  name: 'FinanceiroCategorias',
  mixins: [DefaultMixin, ListMixin],
  components: {
    LayoutPaper,
    DateInput,
    ErpInput,
    ErpSField,
    UFakeLoader,
    CategoriaItem
  },
  data() {
    const _filters = JSON.parse(JSON.stringify(filters))
    return {
      listName: listName,
      filters: _filters,
      loading: false,
      all: []
    }
  },
  computed: {
    receitas() {
      if (!this.all) return []
      return this.all.filter(c => c.tipo === 1)
    },
    despesas() {
      if (!this.all) return []
      return this.all.filter(c => c.tipo === 2)
    }
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    windowNew: windowNew,
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.filters.data1) {
        if (this.filters.data1.length < 10 || this.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.filters.data1)
        data2 = datePtToEn(this.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.filters.tipoData && extraFilters.push(`&typeDate=${this.filters.tipoData}`)

      this.filters.id && extraFilters.push(`&id=${this.filters.id}`)
      this.filters.search && extraFilters.push(`&search=${this.filters.search}`)

      if (Array.isArray(this.filters.status) && this.filters.status.length > 0) {
        extraFilters.push(`&status=${this.filters.status.join(',')}`)
      }

      this.filters.pessoa && extraFilters.push(`&pessoa=${this.filters.pessoa.id || this.filters.pessoa}`)
      this.filters.status && extraFilters.push(`&status=${this.filters.status}`)

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&${extraFilters.join('')}`)

      list(9999, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
            this.all = data.all
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    novo(t = null) {
      console.log('New...')
      this.windowNew(null, t)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.filters.descricao || this.table.busca
      })
    },
    edit(id) {
      this.windowNew(id)
    },
    excluir(id) {
      this.$uloc.dialog({
        title: 'Confirmar exclusão',
        message: 'Tem certeza que deseja excluir esta categoria?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show()
        deleteCategoria(id)
            .then(() => {
              this.$uloc.loading.hide()
              this.$uloc.notify({
                color: 'positive',
                message: `Conta excluída com sucesso.`
              })
              this.load()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      }).catch(() => {
        this.loading = false
      })
    },
    eventRemove(c) {
      this.excluir(c.id)
    },
    eventNew(c) {
      this.windowNew(null, c.tipo, c)
    },
    eventEdit(c) {
      this.edit(c.id)
    }
  },
  meta: {
    title: 'FinanceiroCategorias',
    name: 'FinanceiroCategorias'
  }
}
</script>

<template>
  <div class="fin-categorias-container wrapper-md">
    <div class="sl-layout-page-size m-t m-b">
      <a @click="$router.push({name: 'financeiro.dashboard'})">Financeiro</a> &raquo; Plano de Contas
    </div>
    <layout-paper :loading="loading">
      <div class="dtitle diviser flex row items-start justify-between full-width">
        <span class="col-grow-1" style="font-size: 24px; font-weight: bold">Categorias de Receita</span>
        <u-btn label="Nova Categoria de Receita" no-caps color="primary" class="sl-btn" @click="novo('receita')"/>
      </div>
      <div class="m-t-xs">
        <categoria-item v-for="c in receitas" :key="c.id" :c="c" @remove="eventRemove" @new="eventNew"
                        @edit="eventEdit">
          <div class="level2" v-if="c.childrens">
            <categoria-item v-for="c2 in c.childrens" :key="c2.id" :c="c2" @remove="eventRemove" @new="eventNew"
                            @edit="eventEdit">
              <div class="level3" v-if="c2.childrens">
                <categoria-item v-for="c3 in c2.childrens" :key="c3.id" :c="c3" @remove="eventRemove" @new="eventNew"
                                @edit="eventEdit" is-last-level>
                </categoria-item>
              </div>
            </categoria-item>
          </div>
        </categoria-item>
      </div>

      <div class="dtitle diviser flex row items-start justify-between full-width m-t-lg">
        <span class="col-grow-1" style="font-size: 24px; font-weight: bold">Categorias de Despesa</span>
        <u-btn label="Nova Categoria de Despesa" no-caps color="primary" class="sl-btn" @click="novo('despesa')"/>
      </div>
      <div class="m-t-xs">
        <categoria-item v-for="c in despesas" :key="c.id" :c="c" @remove="eventRemove" @new="eventNew"
                        @edit="eventEdit">
          <div class="level2" v-if="c.childrens">
            <categoria-item v-for="c2 in c.childrens" :key="c2.id" :c="c2" @remove="eventRemove" @new="eventNew"
                            @edit="eventEdit">
              <div class="level3" v-if="c2.childrens">
                <categoria-item v-for="c3 in c2.childrens" :key="c3.id" :c="c3" @remove="eventRemove" @new="eventNew"
                                @edit="eventEdit" is-last-level>
                </categoria-item>
              </div>
            </categoria-item>
          </div>
        </categoria-item>
      </div>
    </layout-paper>
  </div>
</template>
